// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-hr {
  width: 200px; /* Adjust the width as needed */
  border: 1px solid #6566CD; /* Customize the border style and color */
  margin: auto; /* Center the <hr> horizontally */
  margin-left: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/popularproduct/popular.css"],"names":[],"mappings":"AAAA;EACE,YAAY,EAAE,+BAA+B;EAC7C,yBAAyB,EAAE,yCAAyC;EACpE,YAAY,EAAE,iCAAiC;EAC/C,cAAc;AAChB","sourcesContent":[".custom-hr {\n  width: 200px; /* Adjust the width as needed */\n  border: 1px solid #6566CD; /* Customize the border style and color */\n  margin: auto; /* Center the <hr> horizontally */\n  margin-left: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
