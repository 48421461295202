import React from 'react';
import { Link, useParams } from 'react-router-dom';

export const Card = ({ data }) => {
  const colorsList = data.colors ? data.colors.split(',').map(color => color.trim()) : [];
  const { category } = useParams();

  // Get the first image path from the comma-separated string
  const firstImagePath = data.image_paths ? data.image_paths.split(',')[0].trim() : '';

  return (
    <div className="grid place-items-start mx-4  w-56 hover:shadow-gray-700 m-5 hover:scale-110 hover:duration-500 hover:ease-in-out hover:border-black hover:rounded-xl ">
      <Link to={`/${category}/${data.id}`}>
        <img className="w-full h-auto rounded-t-3xl" src={`https://backend-run-79be31c2d90c.herokuapp.com/images/${category}/${firstImagePath}`} alt={data.name} />
        <div className="p-5 flex flex-col justify-between">
          <p className="mb-2 text-base font-semibold tracking-tight text-zinc-500 overflow-hidden">
            {data.name} <br />
            {data.weight}
          </p>
          <div>
            <div className="mb-3">
              <ul className="flex space-x-2">
                {colorsList.length > 0 ? colorsList.map((color, index) => (
                  <li key={index} style={{ backgroundColor: color, width: '15px', height: '15px', borderRadius: '50%', borderColor: 'black',borderWidth: '1px' }}></li>
                )) : (
                  <p>No Colors Available</p>
                )}
              </ul>
            </div>
            <p className="text-base font-bold">RM{data.new_price}</p>
          </div>
        </div>
      </Link>
    </div>
  );
};
