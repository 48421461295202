// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-newsletter{
    background-color:#DFDFFF;
}
.pale-purple{
    color:#6566CD;
}
.bg-btn-purple{
    background-color: #6566CD;
}
`, "",{"version":3,"sources":["webpack://./src/components/subscribe/subscribe.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;AAC5B;AACA;IACI,aAAa;AACjB;AACA;IACI,yBAAyB;AAC7B","sourcesContent":[".bg-newsletter{\n    background-color:#DFDFFF;\n}\n.pale-purple{\n    color:#6566CD;\n}\n.bg-btn-purple{\n    background-color: #6566CD;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
