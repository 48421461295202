// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-color{
    background-color:#2C2C5B ;
}
.bg-color:hover{
    background-color: #FBF5FF;
    color: #2C2C5B;

}
.bg-container{
    background-image: linear-gradient(#FBF5FF, white);
}
.h2-ms,.p-ms{
    color: #2C2C5B;
}`, "",{"version":3,"sources":["webpack://./src/components/megasale/megasale.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;IACzB,cAAc;;AAElB;AACA;IACI,iDAAiD;AACrD;AACA;IACI,cAAc;AAClB","sourcesContent":[".bg-color{\n    background-color:#2C2C5B ;\n}\n.bg-color:hover{\n    background-color: #FBF5FF;\n    color: #2C2C5B;\n\n}\n.bg-container{\n    background-image: linear-gradient(#FBF5FF, white);\n}\n.h2-ms,.p-ms{\n    color: #2C2C5B;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
