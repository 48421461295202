import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './components/navbar/Navbar';
import Home from './Pages/Home';
import Footer from './components/footer/Footer';
import { ItemListPage } from './Pages/ItemListPage';
import { ItemPage } from './components/handleItem/ItemPage';
import { Cart } from './Pages/Cart';
import ShopContextProvider from './shopContext/ShopContext';
import { TestAPI } from './components/testAPI/TestAPI';
import { Error } from './Pages/Error';
import { Login } from './Pages/Login';
import { Register } from './Pages/Register';
import { AuthProvider } from './shopContext/AuthContext';
import { AboutUs } from './Pages/AboutUs';
import { PaymentInfo } from './Pages/PaymentInfo';
import { Return } from './Pages/Return';
import { ContactUs } from './Pages/ContactUs';

export default function App() {
  return (
    <AuthProvider>
      <ShopContextProvider>
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/:category' element={<ItemListPage />} />
            <Route path='/:category/:itemID' element={<ItemPage />} />
            <Route path='/carts' element={<Cart />} />
            <Route path='/test' element={<TestAPI />} />
            <Route path='/checkout' element={<Error />} />
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/aboutus' element={<AboutUs/>}/>
            <Route path='/paymentinfo' element={<PaymentInfo/>}/>
            <Route path='/return' element={<Return/>}/>
            <Route path='/contactus' element={<ContactUs/>}/>
          </Routes>
          <Footer />
        </BrowserRouter>
      </ShopContextProvider>
    </AuthProvider>
  );
}
