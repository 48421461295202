import React, { createContext, useState, useEffect } from 'react';
import useFetchData from './UseFetchData'; // Adjust the path

export const ShopContext = createContext();

const ShopContextProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { datas: products, isLoading, error: fetchError } = useFetchData('https://backend-run-79be31c2d90c.herokuapp.com/products');

  useEffect(() => {
    if (!isLoading && !fetchError) {
      setAllProducts(products);

      const savedCart = localStorage.getItem('cartItems');
      if (savedCart) {
        setCartItems(JSON.parse(savedCart));
      } else {
        setCartItems(getDefaultCart(products));
      }
      
      setLoading(false);
    } else if (fetchError) {
      setError(fetchError);
      setLoading(false);
    }
  }, [products, isLoading, fetchError]);

  const getDefaultCart = (products) => {
    return products.map(product => ({
      id: product.id,
      quantity: 0,
      color: '',
      size: ''
    }));
  };

  const addToCart = (itemId, quantity = 1, color = '', size = '') => {
    setCartItems((prev) => {
      const existingItemIndex = prev.findIndex(item => item.id === itemId && item.color === color && item.size === size);
      let updatedItems;
      if (existingItemIndex > -1) {
        updatedItems = [...prev];
        updatedItems[existingItemIndex] = {
          ...updatedItems[existingItemIndex],
          quantity: updatedItems[existingItemIndex].quantity + quantity,
        };
      } else {
        updatedItems = [...prev, { id: itemId, quantity, color, size }];
      }
      saveCartToLocalStorage(updatedItems); // Save to local storage
      return updatedItems;
    });
  };

  const removeFromCart = (itemId) => {
    setCartItems(prevItems => {
      const updatedItems = prevItems.map(item => {
        if (item.id === itemId) {
          if (item.quantity === 1) {
            return null;
          }
          return { ...item, quantity: item.quantity - 1 };
        }
        return item;
      }).filter(item => item !== null);
      saveCartToLocalStorage(updatedItems); // Save to local storage
      return updatedItems;
    });
  };

  const fullDelete = (itemId, color, size) => {
    setCartItems((prev) => {
      const filteredItems = prev.filter(item => {
        const itemColor = item.color || 'No color selected';
        const itemSize = item.size || 'No size selected';
        return !(item.id === itemId && itemColor === color && itemSize === size);
      });
      console.log('Updated cart items:', filteredItems);
      saveCartToLocalStorage(filteredItems); // Save to local storage
      return filteredItems;
    });
  };

  const updateCartItemQuantity = (itemId, newQuantity) => {
    setCartItems(prev => {
      const updatedItems = prev.map(item => 
        item.id === itemId ? { ...item, quantity: newQuantity } : item
      );
      saveCartToLocalStorage(updatedItems); // Save to local storage
      return updatedItems;
    });
  };

  const getTotalCartAmount = () => {
    return cartItems.reduce((totalAmount, item) => {
      if (item.quantity > 0) {
        const itemInfo = allProducts.find(product => product.id === item.id);
        if (itemInfo) {
          return totalAmount + itemInfo.new_price * item.quantity;
        }
      }
      return totalAmount;
    }, 0);
  };

  const saveCartToLocalStorage = (cart) => {
    console.log('Saving cart to local storage:', cart);
    localStorage.setItem('cartItems', JSON.stringify(cart));
  };

  const getTotalCartItems = () => {
    return cartItems.reduce((totalItems, item) => totalItems + item.quantity, 0);
  };

  const contextValue = {
    allProducts,
    cartItems,
    addToCart,
    removeFromCart,
    updateCartItemQuantity,
    getTotalCartAmount,
    getTotalCartItems,
    fullDelete,
    loading,
    error,
    getDefaultCart,
    saveCartToLocalStorage,
    setCartItems // Add this
  };

  return (
    <ShopContext.Provider value={contextValue}>
      {children}
    </ShopContext.Provider>
  );
};

export default ShopContextProvider;
