import React from 'react';
import { Card } from '../components/card/Card';
import { Link, useParams } from 'react-router-dom';
import useFetchData from '../shopContext/UseFetchData';
import { ErrorCpnt } from '../components/error/ErrorCpnt';
import { Loading } from '../components/loader/Loading';

export const ItemListPage = () => {
  const { category } = useParams(); // Extract category from URL parameters
  // Construct the API URL based on the category
  const apiUrl = category ? `https://backend-run-79be31c2d90c.herokuapp.com/products/${category}` : 'https://backend-run-79be31c2d90c.herokuapp.com/products';
  const { datas, isLoading, error } = useFetchData(apiUrl);
  if (isLoading) return <Loading/>;
  if (error) return <ErrorCpnt/>;

  return (
    <div className="max-w-7xl px-2 mx-auto sm:px-6 lg:px-10 m-2 flex my-4 md:my-8 flex-col">
      <div>
        <nav className="flex justify-center m-5" aria-label="Breadcrumb">
          <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
            <li className="inline-flex items-center">
              <Link to="/" className="inline-flex items-center text-sm font-medium text-gray-700">
                Home
              </Link>
            </li>
            <li>
              <div className="flex items-center">
                <svg
                  className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                </svg>
                <Link to={`/products/${category}`} className="ms-1 text-sm font-medium text-gray-700">
                  {category ? category.charAt(0).toUpperCase() + category.slice(1) : 'Products'}
                </Link>
              </div>
            </li>
          </ol>
        </nav>
        <p className="text-7xl text-black text-center">{category ? category.charAt(0).toUpperCase() + category.slice(1) : 'Products'}</p>
        <hr className="w-44 mx-auto mt-5 border-2 border-violet-700" />
        <p className="text-center m-3">Showing {datas.length} products</p>
      </div>
      <div className='grid justify-center'>
      <div className="flex space-x-4 flex-wrap space-y-4 mt-24 justify-start max-w-5xl gap-4">
        {datas.map(data => (
          <Card key={data.id} data={data} />
        ))}
      </div>
      </div>

    </div>
  );
};
